<template>
  <div class="about">
    <About></About>
  </div>
</template>

<script>
import About from "@/components/About";

export default {
  name: "AboutView",
  components: {
    About
  }
};
</script>
